import {
  post
} from "../Http";

export default {

  /**
   * @method 获取上传凭证
   * @param {*} actId 活动id,默认为0
   * @param {*} category 文件分类 common, navi, product, brand, product
   * @param {*} extension 文件后缀
   * @param {*} fileType 文件类型 image, video
   */
  getUploadCertificate: params =>
    post(`admin/v1/activity/upload/certificate`, params, false),
}
