import $ from "jquery";
import COS from "COS";
import CommonRequest from "./modules/Common.request";

export default class VzanUpload {
  constructor(uploadParams) {
    this.Region = "ap-guangzhou";
    // 其他存储桶
    this.Bucket = "yx-video-1251575313";
    this.Host = "https://yx-video-1251575313.cos.ap-guangzhou.myqcloud.com";
    // 图片存储桶
    this.imageBucket = "yx-image-1251575313";
    this.imageHost = "https://yx-image-1251575313.cos.ap-guangzhou.myqcloud.com";

    this.filename = "";
    this.cos = new COS();

    this.uploadParams = uploadParams;
  }

  // // 获取上传文件签名
  // getAuthorization() {
  //   const url = `http://192.168.30.178:8802/v1/activity/upload/certificate`;
  //   return new Promise((rseolve, reject) => {
  //     $.ajax({
  //       url,
  //       method: "POST",
  //       data: {
  //         actId: 0,
  //         category: "common",
  //         extension: "jpg",
  //         fileType: "image"
  //       },
  //       dataType: "JSON",
  //       success: rseolve,
  //       error: reject
  //     });
  //   });
  // }

  // 验证上传文件
  valideFileConfig(fileType, fileExe, config) {
    let msg = "";
    let isImage = false;
    if (fileType.includes("image")) {
      // 图片
      isImage = true;
      console.log(config.fileTypes);
      const fileTypesArr = config.fileTypes || ["jpeg", "jpg", "png","gif"];
      if (!fileTypesArr.includes(fileExe)) {
        msg = `该文件类型不支持，请上传${fileTypesArr.join(",")}文件`;
      }
    } 
    // else if (config?.config.fileTypes.includes("excel")) {
      
    //   console.log(114514);
    //   if (!fileExe.includes("sheet") && !fileExe.includes("excel")) {
    //     // excel 表格
    //     msg = "该文件类型不支持，请上传 Excel xls 文件";
    //   }
    // } 
    else {
      // 视频文件
      const fileTypesArr = config.fileTypes || ["mp4"];
      if (!fileTypesArr.includes(fileExe)) {
        msg = `该文件类型不支持，请上传${fileTypesArr.join(",")}文件`;
      }
    }
    console.log(msg);
    return { msg, isImage };
  }

  // 上传图片
  uploadImage(inputEvent, config = {}) {
    return this.uploadFile(inputEvent, config, "image");
  }

  // 上传文件
  async uploadFile(inputEvent, config = {}) {
    const fileObject = inputEvent.target.files[0];
    console.log(fileObject);
    if (!fileObject) {
      throw new Error("找不到改文件");
    }
    console.log(fileObject);
    const fileType = fileObject.type;
    let fileExe = fileType.split("/")[1];
    console.log(fileExe);
    console.log(config);
    const { msg, isImage } = this.valideFileConfig(fileType, fileExe, config);
    if (msg) {
      throw new Error(msg);
    }
    const filesize = Number(fileObject.size / 1024 / 1024);
    
    const maxSize = config.maxSize || 100;
    console.log(maxSize);
    console.log(filesize);

    if (filesize > maxSize) {
      throw new Error(`上传文件不能超过${maxSize}Mb`);
    }
    try {
      // excel 文件格式 扩展名转换
      if (fileExe.includes("excel") || fileExe.includes("sheet")) {
        fileExe = "xls";
      }
      // 随机文件名
      this.filename = `${Math.random().toString(36).substr(2)}.${fileExe}`;
      console.log(this.filename);
      // TODO: 获取签名
      const { data } = await CommonRequest.getUploadCertificate(this.uploadParams);
      this.cos.options.SecretId = data?.credentials?.tmpSecretId;
      this.cos.options.SecretKey = data?.credentials?.tmpSecretKey;
      this.cos.options.XCosSecurityToken = data?.credentials?.sessionToken;
      // this.cos.options.ExpiredTime = auth.ExpiredTime;
      this.filename = data?.key;

    } catch (error) {
      throw new Error("上传路中出错了，请稍候再试试~");
    }
    return new Promise((rseolve, reject) => {
      inputEvent.target.value = null;
      $(event.target.id).val("");
      const fromType = inputEvent.target.dataset.type;
      
      console.log(fromType);
      // 本地调试会跟腾讯云跨域，所以本地则返回固定值
        console.log(location.host.includes("local"),location.host.includes("192.168."))
      if (location.host.includes("local") || location.host.includes("192.168.")) {
        if (fileObject.type=="video/mp4") {
          rseolve({
            src: "https://i3.vzan.cc/m3u8/20200410/a208572ad7754258b1351ccda9e4a379/v.f24306.m3u8",
            fileType: "video/mp4",
            fileExe: "mp4",
            fromType,
            fileSize: 5622277,
            fileSizeFormat: "0.02Mb"
          });
        } else {
          rseolve({
            src: "https://yx-image-1251575313.cos.ap-guangzhou.myqcloud.com/test/upload/image/2020/07/20/f0d3c3eccc574ccf9c0b2800f61a7fcf.jpg",
            fileType: "image/jpg",
            fileExe: "jpg",
            fromType,
            fileSize: 24336,
            fileSizeFormat: "0.02Mb"
          });
        }
      } else {
        // 切片上传
        this.cos.sliceUploadFile({
          Bucket: isImage ? this.imageBucket : this.Bucket,
          Region: this.Region,
          Key: this.filename,
          Body: fileObject, // 文件对象
          // onTaskReady return taskId
          onProgress: progressData => {
            // 进度
            config?.config.onProgress(parseInt(progressData.percent * 100));
          }
        }, (err, data) => {
          console.log(data,err)
          if (err) {
            console.log(err);
            reject("上传失败请重试");
          } else {
            const imageUploadUrl = (isImage ? this.imageHost : this.Host) +'/'+ data.Key;
            const imageInfo = {
              src: imageUploadUrl,
              fileType,
              fileExe,
              fromType,
              fileSize: fileObject.size,
              fileSizeFormat: `${(fileObject.size / 1024 / 1024).toFixed(2)}Mb`
            };
            console.log(imageInfo)
            rseolve(imageInfo);
          }
        })
      }
    });
  }
}
